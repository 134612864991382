import { getPopularProductsAsins, getMultipleProducts } from './api';
import { Product } from '../types/api';
import { transformSearchResults } from '../utils/transformers';

export const fetchPopularProducts = async (): Promise<Product[]> => {
  try {
    const asins = await getPopularProductsAsins();
    if (!asins?.length) {
      console.warn('No ASINs received from popular deals API');
      return [];
    }

    const products = await getMultipleProducts(asins);
    if (!products?.length) {
      console.warn('No products received from multiple products API');
      return [];
    }

    // Transformer les produits pour correspondre au format attendu
    const validProducts = products
      .map(p => {
        // Filtrer les productData avec un prix valide (supérieur à 0)
        const validProductData = p.productData.filter(pd => pd.price > 0);

        // Si aucun productData valide n'est trouvé, ignorer ce produit
        if (!validProductData.length) {
          return null;
        }

        // Trouver les informations pour la France parmi les productData valides
        const franceData = validProductData.find(pd => pd.country === 'fr');

        // Si aucune donnée pour la France n'est trouvée, utiliser la première disponible
        const productData = franceData || validProductData[0];

        // Regrouper tous les prix valides
        const prices = validProductData.reduce((acc, pd) => {
          acc[pd.country] = pd.price;
          return acc;
        }, {} as Record<string, number>);

        return {
          asin: p.asin,
          name: productData.name || 'Product name not found',
          prices,
          image: productData.imgarrays?.[0] 
            ? `https://images-na.ssl-images-amazon.com/images/I/${productData.imgarrays[0]}.jpg` 
            : 'https://via.placeholder.com/150',
          rating: productData.productRating,
          reviews: productData.reviews,
          productData: validProductData
        };
      })
      .filter(Boolean) as {
        asin: string;
        name: string;
        prices: Record<string, number>;
        image: string;
        rating: number;
        reviews: number;
        productData: {
          price: number;
          deliveryPrice: number;
          fullPrice: number;
          availability: string;
          deliveryDate: string;
          currencySymbol: string;
          country: string;
          techSpechs: { [key: string]: string }[];
          features: string[];
          productRating: number;
          imgarrays: string[];
          name: string;
          weight: number;
          brand: string;
          reviews: number;
          unitsSold: number;
          variations: any[];
          userComments: string[];
          lastUpdated: string;
        }[];
      }[]; // Typage explicite pour garantir l'absence de null

    const transformedProducts = transformSearchResults(validProducts);
    if (!transformedProducts?.length) {
      console.warn('No products after transformation');
      return [];
    }

    return transformedProducts;
  } catch (error) {
    console.error('Error fetching popular products:', error);
    return [];
  }
};
