import axios from 'axios';

const searchApi = axios.create({
    baseURL: import.meta.env.VITE_API_URL,
    timeout: 10000, // 10 seconds
    headers: {
        'Content-Type': 'application/json',
    },
});

const popularDealsApi = axios.create({
    baseURL: 'https://corsproxy.io/?url=' + 'https://popular-deals.vercel.app',
    timeout: 10000,
});

const PAGE_OFFSETS = [0, 11, 23, 35, 47];

export const searchProducts = async (query: string, pageIndex = 0, limit = 12): Promise<any[]> => {
    try {
        const offset = PAGE_OFFSETS[pageIndex];
        if (offset === undefined) {
            return [];
        }

        const locales = ['com.be', 'fr', 'de', 'es', 'it', 'co.uk'];
        const { data } = await searchApi.get('/search_products', {
            params: {
                query,
                locales: locales.join(','),
                region: 'fr',
                limit,
                page: offset,
            },
        });

        if (!Array.isArray(data.productsData)) {
            console.error('Invalid data structure received from search API');
            return [];
        }

        return data.productsData;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error('Error searching products:', error.message);
            if (error.code === 'ERR_NETWORK') {
                console.error('Network error: Check your connection or API availability.');
            }
        } else {
            console.error('Unexpected error:', error);
        }
        throw error;
    }
};

export const getPopularProductsAsins = async (): Promise<string[]> => {
    try {
        const { data } = await popularDealsApi.get('/products');
        if (!data?.products) {
            throw new Error('Invalid response format from popular deals API');
        }
        return data.products.map((product: { asin: string }) => product.asin).filter(Boolean);
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error('Error fetching popular products ASINs:', error.message);
        } else {
            console.error('Unexpected error:', error);
        }
        return [];
    }
};

interface ProductResponse {
  asin: string;
  productData: {
    price: number;
    deliveryPrice: number;
    fullPrice: number;
    availability: string;
    deliveryDate: string;
    currencySymbol: string;
    country: string;
    techSpechs: { [key: string]: string }[];
    features: string[];
    productRating: number;
    imgarrays: string[];
    name: string;
    weight: number;
    brand: string;
    reviews: number;
    unitsSold: number;
    variations: any[];
    userComments: string[];
    lastUpdated: string;
  }[];
}

export const getMultipleProducts = async (asins: string[]): Promise<ProductResponse[]> => {
    try {
        if (!asins.length) return [];

        const locales = ['fr', 'com.be', 'de', 'it', 'es', 'co.uk'];
        const { data } = await searchApi.get('/get_multiple_products', {
            params: {
                asins: asins.join(','),
                locales: locales.join(','),
                region: 'fr',
            },
        });

        if (!data?.productsData || !Array.isArray(data.productsData)) {
            console.error('Invalid data structure received from multiple products API');
            return [];
        }

        return data.productsData;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error('Error fetching multiple products:', error.message);
        } else {
            console.error('Unexpected error:', error);
        }
        return [];
    }
};
